import { Combobox } from "@headlessui/react";
import { format } from "date-fns";
import * as F from "fuse.js";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import classNames from "utils/classnames";
import { useDebounce } from "../../utils/hooks/debounce";
import LoaderIcon from "../assets/loader.svg";

interface Course {
  title: string;
  id: string;
  link: string;
  nextEvent: {
    start: string;
  };
  description: string;
  categories: Array<string>;
  image_link: string;
  brand: string;
  price: number;
}

export default function HeaderSearch({ show, close }) {
  const [state, setState] = useState({
    displayed: show,
    term: null,
    loading: true,
    result: [],
    courses: [],
  });

  useEffect(() => {
    if (show && !state.displayed) {
      setState((s) => ({ ...s, displayed: true }));
    } else if (!show && state.displayed) {
      setState((s) => ({ ...s, displayed: false }));
    }
  }, [show, state.displayed]);

  useEffect(() => {
    async function fetchCatalog() {
      try {
        const req = await fetch(`/api/catalog?courses=true&start`);
        const res = await req.json();
        setState((s) => ({ ...s, courses: res, loading: false }));
      } catch (error) {
        console.error(error);
        setTimeout(() => fetchCatalog(), 2500);
      }
    }
    fetchCatalog();
  }, []);

  const debouncedSearchTerm = useDebounce(state.term, 500);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
      window.gtag &&
        window.gtag("event", "search", {
          search_term: debouncedSearchTerm,
        });
    }
  }, [debouncedSearchTerm]);

  const searchKeysArray = ["title"];

  const fuse = useRef(
    new F.default(state.courses, {
      includeMatches: true,
      minMatchCharLength: 1,
      threshold: 0.3,
      keys: searchKeysArray,
    })
  );

  useEffect(() => {
    fuse.current = new F.default(state.courses, {
      includeMatches: true,
      minMatchCharLength: 1,
      threshold: 0.3,
      keys: searchKeysArray,
    });
  }, [state.courses]);

  useEffect(() => {
    if (state.term && state.term.length > 1) {
      let result = fuse.current.search(state.term);
      // @ts-ignore
      result = result.map((item) => {
        return item.item;
      });

      setState({ ...state, result });
    } else {
      setState({ ...state, result: [] });
    }
  }, [state.term, state.courses]);

  return (
    <div
      className={`flex flex-col transition-all z-50 w-full ${
        state.displayed
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      }`}
    >
      <SearchBar
        loading={state.loading}
        items={state?.result?.filter((item) => item.link)}
        onChange={(e) => setState((s) => ({ ...s, term: e.target.value }))}
      />
    </div>
  );
}

function SearchBar({
  items,
  onChange,
  loading,
}: {
  items: Course[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
}) {
  return (
    <Combobox as="div" className="w-full">
      <div className="relative w-full">
        <Combobox.Input
          placeholder="Search for a training course"
          className="w-full flex-1 rounded-md border-0 bg-white py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-brand-600 text-xs sm:leading-6 max-w-xl"
          onChange={(event) => onChange(event)}
        />

        <Combobox.Options className="absolute z-10 mt-1 max-h-80 w-screen -left-2 md:left-0  md:w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm max-w-xl">
          {items && items.length > 0 ? (
            items.map((item) => {
              if (!item.link) {
                return null;
              }

              return (
                <Combobox.Option
                  key={item.id}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 px-3",
                      active ? "bg-brand-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <Link
                      href={
                        process.env.NODE_ENV === "development"
                          ? item.link.replace(
                              "https://cosmetic.college/",
                              "http://localhost:3000/"
                            )
                          : item.link ?? ""
                      }
                    >
                      <div className="flex items-center w-full">
                        <img
                          src={item.image_link ?? ""}
                          alt=""
                          className="h-6 w-6 flex-shrink-0 rounded-full"
                        />

                        <div className="ml-3 truncate w-full">
                          <p
                            className={classNames(
                              selected && "font-semibold truncate w-full",
                              "text-xs lg:text-base"
                            )}
                          >
                            {item.title}
                          </p>
                          {item.nextEvent && (
                            <p
                              className={classNames(
                                active ? "text-gray-100" : "text-gray-500",
                                "font-medium text-xs"
                              )}
                            >
                              Next Available Date:{" "}
                              <span className="font-normal">
                                {format(
                                  new Date(item.nextEvent.start),
                                  "do MMM yyyy"
                                )}
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                    </Link>
                  )}
                </Combobox.Option>
              );
            })
          ) : loading ? (
            <Combobox.Option
              disabled
              value={null}
              className={({ active }) =>
                classNames(
                  "relative cursor-default select-none py-2 px-3 flex items-center justify-center w-full gap-2.5 h-12",
                  active ? "bg-brand-600 text-white" : "text-gray-900"
                )
              }
            >
              {({ selected }) => (
                <>
                  <Loader />
                  <p className={classNames(selected && "font-semibold")}>
                    Loading Results
                  </p>
                </>
              )}
            </Combobox.Option>
          ) : (
            <Combobox.Option
              disabled
              value={null}
              className={({ active }) =>
                classNames(
                  "relative cursor-default select-none py-2 px-3 flex items-center justify-center w-full gap-1.5 h-12 text-center",
                  active ? "bg-brand-600 text-white" : "text-gray-900"
                )
              }
            >
              {({ selected }) => (
                <p className={classNames(selected && "font-semibold")}>
                  No Results Found
                </p>
              )}
            </Combobox.Option>
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

const Loader = () => (
  <div className="flex items-center justify-center">
    <LoaderIcon width={25} height={25} stroke="#0C9498" />
  </div>
);
